 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="sm"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">Cargo info</h2>
        </div>

        <div slot="body">
            <div v-if="loading" class="flex flex__justify-center w__100-p">
                <spinner />
            </div>

            <form v-else @submit.prevent="">
                <div class="flex flex__justify-between mb-3">
                    <modal-field class="w-full mr-3" label="Number of pakages">
                        <input
                            v-model="noofpackages"
                            class="w-full"
                            type="number"
                            :class="{'is-invalid': submitter && $v.noofpackages.$invalid}"
                        />
                    </modal-field>

                    <modal-field class="w-full ml-3" label="Volume">
                        <input
                            v-model="dimensions_volume"
                            class="w-full"
                            type="number"
                            :class="{'is-invalid': submitter && $v.dimensions_volume.$invalid}"
                        />
                    </modal-field>
                </div>

                <div class="flex flex__justify-between mb-3">
                    <modal-field class="w-full mr-3" label="Weight">
                        <input
                            v-model="dimensions_weight"
                            class="w-full"
                            type="number"
                            :class="{'is-invalid': submitter && $v.dimensions_weight.$invalid}"
                        />
                    </modal-field>

                    <modal-field class="w-full ml-3" label="Loading meters">
                        <input
                            v-model="dimensions_loadingmetres"
                            class="w-full"
                            type="number"
                            :class="{'is-invalid': submitter && $v.dimensions_loadingmetres.$invalid}"
                        />
                    </modal-field>
                </div>

                <div class="flex flex__justify-between mb-3">
                    <modal-field v-if="types.length" class="w-full" label="Type">
                        <multiselect
                            v-model="type"
                            :options="types"
                            class="relative"
                            :label="typeLabel"
                            :class="{'is-invalid': submitter && $v.type.$invalid}"
                        >
                            <template slot="option" slot-scope="{ option }">
                                {{ option.name || option.code }}
                            </template>
                        </multiselect>
                    </modal-field>
                </div>  
            </form>
        </div>
        
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <div class="flex">
                    <button class="px-10 btn-primary" @click="save">Save</button>
                    
                    <button class="px-8 ml-4 btn-grey-outline" @click="$emit('hide')">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import axios from 'axios';

import Spinner from '~/components/Spinner.vue';

export default {
    name: 'GoodsInfoModal',

    components: {
        Spinner,
    },

    props: {
        value: {
            type: Object,
            default: () => { return {} },
        }
    },

    data() {
        return {
            noofpackages: this.value.noofpackages,
            dimensions_volume: this.value.dimensions_volume,
            dimensions_weight: this.value.dimensions_weight,
            dimensions_loadingmetres: this.value.dimensions_loadingmetres,
            type: this.value.goods_type_name,
            submitter: false,
            types: [],
            loading: true,
        }
    },

    computed: {
        searchType() {
            if (this.value.carrier_id) {
                return `carrier_id=${this.value.carrier_id}`;
            }

            if (this.value.carrier_name) {
                return `carrier_name=${this.value.carrier_name}`;
            }

            return null;
        },

        typeLabel() {
            if (!this.type) {
                return '';
            }

            if (typeof this.type === 'string') {
                return '';
            }

            if (this.type.name) {
                return 'name';
            }

            return 'code';
        }
    },

    methods: {
        async save() {
            this.submitter = true;

            if (this.$v.$invalid) {
                return;
            }

            this.$emit('save', {
                noofpackages: this.noofpackages,
                dimensions_volume: this.dimensions_volume,
                dimensions_weight: this.dimensions_weight,
                dimensions_loadingmetres: this.dimensions_loadingmetres,
            });

            await this.saveGoodsType();

            this.$emit('hide');
        },

        async saveGoodsType() {
            if (!this.type) {
                return;
            }

            if (typeof this.type === 'string') {
                return;
            }

            try {
                await axios.patch(`${this.$apiUrl.consignments.base}/${this.value.uuid}/goods-type`,{
                    good_type_id: this.type.id,
                });

            } catch(error) {
                console.error(error);
            }
        },

        async initTypes() {
            if (!this.searchType) {
                return;
            }

            const result = await axios.get(this.$apiUrl.goodTypeDictionary.search + `?${this.searchType}`)

            this.types = result.data;
        }
    },

    async created() {
        await this.initTypes();

        this.loading = false;
    },

    validations: {
        noofpackages: {
            required,
            max: (value) => {
                return +value > 0
            }
        },
        dimensions_volume: {
            required,
        },
        type: {
            required: requiredIf(function() {
                return this.types.length;
            }),
        },
        dimensions_weight: {
            required,
            max: (value) => {
                return +value > 0
            }
        },
        dimensions_loadingmetres: {
            required,
        },
    }
}
</script>
